import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getCampaign } from '../../../firebase/campaign';

const columns = (_btn) => [
    {
        name: 'Title',
        selector: row => row.name,
    },
    {
        name: 'Start & End Date',
        selector: row => row.startdate+' '+row.enddate,
    },
    {
        name: 'Status',
        selector: row => row.status,
    },
    {
        name: '',
        right: true,
        selector: row => _btn(row),
    },
];

const Campaign = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCampaign().then(res => {
            setData(res);
            setLoading(false);
        });
    },[]);

    return (
        <div>
            <h1 className="text-4xl mb-10 font-ProductSansBold">Campaign</h1>

            <div className='shadow-md mb-10'>
                <div className='p-4'>
                    <h1 className="text-2xl mb-2">List of Campaign</h1>
                    <button 
                        onClick={() => window.location.href = '/admin/add-campaign'}
                        className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-emerald-500 text-white'>
                        Add Campaign
                    </button>
                </div>
                
                <DataTable
                    columns={columns(_btn)}
                    data={data}
                    pagination
                    progressPending={loading}
                    />
            </div>
            
        </div>
    )

    function _btn(row) {
        return (
            <div className='flex gap-2'>
                <button 
                    onClick={() => window.location.href = '/admin/generate-campaign-coupon/'+row.key}
                    className='font-ProductSansRegular tracking-wider rounded-md p-2 bg-orange-500 text-white'>
                    Coupons
                </button>
                <button 
                    onClick={() => window.location.href = '/admin/edit-campaign/'+row.key}
                    className='font-ProductSansRegular tracking-wider rounded-md p-2 bg-cyan-500 text-white'>
                    Edit
                </button>
            </div>
        )
    }
}

export default Campaign;