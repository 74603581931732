import logo from '../assets/images/logo-main.png';
import { MdPlayArrow } from 'react-icons/md';
import { FaFacebookF, FaTwitter, FaGoogle } from 'react-icons/fa';

const Footer = (props) => {
    return (
        <footer className="bg-black p-4">
            <div className="container mx-auto max-w-6xl grid grid-cols-1 gap-8 md:grid-cols-2">
                {/* left */}
                <div>
                    <img 
                        src={logo}
                        width={150}
                        alt=""
                    />
                    <p className="font-ProductSansRegular tracking-wider text-white/80 mt-4 text-sm mb-8">
                        We ensure the genuineness of Parboiled White Rice, Longed Grain Rice and Basmati Rice through our high tech machines. We have quality equipments with which we can shield our products against dust, grime, and moisture.Due to the same, we are internationally recognized as one of the renowned Exporters of Indian Rice. In addition to the above, we are one of the biggest exporter of jeerakashala , and swarna rice
                    </p>

                    <div className="flex items-center gap-2">
                        <p className="text-[#c0a200] mr-2">FOLLOW US:</p>
                        <SocialContent icon={<FaFacebookF className="text-white"/>}/>
                        <SocialContent icon={<FaTwitter className="text-white"/>}/>
                        <SocialContent icon={<FaGoogle className="text-white"/>}/>
                    </div>
                </div>

                {/* center */}
                <div>
                    <h1 className="text-white/60 font-ProductSansBold tracking-wider text-lg mb-2">CONTACT US</h1>

                    <div className="grid grid-cols-1 divide-y divide-white/50">
                        <div>
                            <p className="text-white/80 text-sm mb-4 mt-4">Old Passport Office Building, Big Bazar, Calicut, Kerala, India-673001</p>
                        </div>
                        <div>
                            <p className="text-sm mb-2 mt-4 font-ProductSansBold text-[#c0a200]">Call Us:</p>
                            <p className="text-white/80 text-sm mb-4">+(91) 88014 98888, +(91) 99955 55171</p>
                        </div>
                        <div>
                            <p className="text-sm mb-2 mt-4 font-ProductSansBold text-[#c0a200]">Email:</p>
                            <p className="text-white/80 text-sm mb-4">varishtraders@gmail.com</p>
                        </div>
                        <div />
                    </div>
                </div>

                {/* right */}
                {/* <div>
                    <h1 className="text-white/60 font-ProductSansBold tracking-wider text-lg mb-2">FOLLOW US</h1>
                    <div className="grid grid-cols-1 divide-y divide-white/50">
                        <div className="flex items-center cursor-pointer">
                            <MdPlayArrow className="text-white/80 mr-2"/>
                            <p className="text-white/80 text-sm mb-4 mt-4">HOME</p>
                        </div>
                        <div className="flex items-center cursor-pointer">
                            <MdPlayArrow className="text-white/80 mr-2"/>
                            <p className="text-white/80 text-sm mb-4 mt-4">ABOUT</p>
                        </div>
                        <div className="flex items-center cursor-pointer">
                            <MdPlayArrow className="text-white/80 mr-2"/>
                            <p className="text-white/80 text-sm mb-4 mt-4">PRODUCTS</p>
                        </div>
                        <div className="flex items-center cursor-pointer">
                            <MdPlayArrow className="text-white/80 mr-2"/>
                            <p className="text-white/80 text-sm mb-4 mt-4">CONTACT</p>
                        </div>
                        <div />
                    </div>
                </div> */}

            </div>

            <p className="text-center text-white/60 mt-8 text-xs">© Copyright ANC. All Rights Reserved</p>
        </footer>
    )
}

const SocialContent = (props) => {
    return (
        <div className="border rounded-full border-white p-3 cursor-pointer hover:bg-[#3ec1d5] transition duration-300">
            {props.icon}
        </div>
    )
}

export default Footer;