import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { addCoupons, getCouponsByCampaign } from '../../../firebase/coupons';
import { getCampaignByKey } from "../../../firebase/campaign";
import moment from 'moment';
import DataTableExtensions from 'react-data-table-component-extensions';
import ExportFile from './exportFile';

const columns = () => [
    {
        name: 'Campaign Name',
        selector: row => !row.campaignName ? row.campaignId : row.campaignName,
    },
    {
        name: 'Code',
        selector: row => row.code
    },
    {
        name: 'Generated Date',
        selector: row => row.generatedDate,
    }
];

const Generate = (props) => {

    const { key } = props.match.params;

    const [data, setData]       = useState([]);
    const [name, setName]       = useState('');
    const [noOfCoupons, setNoOfCoupons]       = useState(0);
    const [loading, setLoading] = useState(true);
    const [generateLoading, setGenerateLoading] = useState(false);

    const tableData = {
        columns: columns(),
        data,
    };

    useEffect(() => {
        _getCoupons();
        getCampaignByKey(key).then(res => {
            setName(res.name);
        });
    },[]);

    function _getCoupons() {
        getCouponsByCampaign(key).then(res => {
            setData(res);
            setLoading(false);
        });
    }

    return (
        <div className="main">
            <h1 className="text-4xl mb-10 font-ProductSansBold">Generate Campaign Coupon</h1>

            <button 
                disabled={loading}
                onClick={() => window.location.href = '/admin/campaign'}
                className='font-ProductSansRegular tracking-wider mt-4 bg-slate-600 text-white rounded-md p-2'>
                Back
            </button>

            <div className='shadow-md mb-10 mt-10'>
                <div className='p-4'>
                    <span className="font-ProductSansBold">Generate Coupons</span>
                    <select
                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                        value={noOfCoupons}
                        onChange={(e) => setNoOfCoupons(e.target.value)}
                        >
                        <option value="">Please Select</option>
                        <option value={100}>100</option>
                        <option value={1000}>1000</option>
                        <option value={5000}>5000</option>
                        <option value={10000}>10000</option>
                        <option value={20000}>20000</option>
                        <option value={30000}>30000</option>
                    </select>
                    <button 
                        disabled={generateLoading}
                        onClick={() => _generateCoupons()}
                        className='font-ProductSansRegular tracking-wider h-50 rounded-md p-2 bg-emerald-500 text-white'>
                        {generateLoading ? 'Please wait... Generating on progress' : 'Generate'}
                    </button>
                </div>
            </div>

            <div className='shadow-md mb-10 mt-10'>
                <div className='p-4'>
                    <h1 className="text-2xl mb-2">List of Coupons</h1>
                </div>
                
                <DataTableExtensions 
                    {...tableData} 
                    print={false}
                    export={false}
                    >
                    <DataTable
                        // columns={columns()}
                        // data={data}
                        noHeader
                        pagination
                        progressPending={loading}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<ExportFile fileData={data} fileName={name+'-coupons'}/>}
                        />
                </DataTableExtensions>
            </div>
            
        </div>
    )

    function _generateCoupons() {

        if(noOfCoupons === 0) {
            toast.error('Please select how many coupons to be generated');
            return false;
        }

        setGenerateLoading(true);
        if(noOfCoupons !== 0) {
            let total = 0;
            for(var i=0; i<noOfCoupons; i++) {
                let params = {
                    campaignId: key,
                    campaignName: name,
                    code: _generateCode(8),
                    generatedDate: moment().format('YYYY-MM-DD')
                }

                total++;
                addCoupons(params);
            }

            if(total === parseInt(noOfCoupons)) {
                setGenerateLoading(false);
                setNoOfCoupons(0);
                _getCoupons();
            }
        }
    }

    function _generateCode(length) {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }
}

export default Generate;