import { orderByChild, set, push, equalTo, query, onValue, ref, update } from "firebase/database";
import moment from "moment";
import { usersRef, db } from "./";
import { encode, decode } from 'js-base64';

let salt = "c9e18815171babb1f190b7aacb7759775ac67941";
let pepper = "09feb137fd5e58ac9131bff6c66ba2e70260bf8c";

export function addUser() {
    return new Promise((resolve, reject) => {

        var hashPassword = salt+encode('123456')+pepper;

        set(push(ref(db, usersRef)), {
            access: 'admin_'+hashPassword,
            type: 'admin',
            username: 'admin',
            password: hashPassword,
            createdDate: moment().format('YYYY-MM-DD h:mm:ss')
        });
        resolve('saved');
    });
}

export function loginUser(state) {
    return new Promise((resolve, reject) => {
        var hashPassword = salt+encode(state.password)+pepper;
        let access = state.username+'_'+hashPassword;

        onValue(query(ref(db, usersRef), orderByChild('access'), equalTo(access)), (snapshot) => {
            if(snapshot.val() === null) {
                resolve('invalid');
            }else {
                var item = [];
                snapshot.forEach(child => {
                    item.push({
                        key: child.key,
                        ...child.val()
                    })
                })
                resolve(item[0].key);
            }
        });
    });
}

export const checkOldPassword = (key, password) => {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, usersRef + key);
        onValue(query(dataRef), (snapshot) => {
            var hashPassword = salt+encode(password)+pepper;
            if(snapshot.val().password === hashPassword) {
                resolve('equal');
            }else {
                resolve('not_equal');
            }
        });
    });
}

export const changePassword = (key, newPassword) => {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, usersRef + key);
        onValue(query(dataRef), (snapshot) => {
            var hashPassword = salt+encode(newPassword)+pepper;
            update(dataRef, {
                access: snapshot.val().username+'_'+hashPassword,
                password: hashPassword
            })
            resolve('save');
        });
    });

    
}