import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getDraws } from '../../../firebase/draws';

const columns = (_btn) => [
    {
        name: 'Title',
        selector: row => row.title,
    },
    {
        name: 'Number of Winners',
        selector: row => row.noOfWinners,
    },
    {
        name: 'Include Previous Winner',
        selector: row => row.includePrevWinners,
    },
    {
        name: 'Created Date',
        selector: row => row.createdDate,
    },
    {
        name: '',
        right: true,
        selector: row => _btn(row),
    },
];

const winnerColumns = [
    {
        name: 'Full Name',
        selector: row => row.fullname,
    },
    {
        name: 'Phone Number',
        selector: row => row.phoneNumber,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Registered Date',
        selector: row => row.registeredDate,
    },
];

const Draws = (props) => {

    const [data, setData] = useState([]);
    const [winners, setWinners] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDraws().then(res => {
            setData(res);
            setLoading(false);
        });
    },[]);

    return (
        <div>
            <h1 className="text-4xl mb-10 font-ProductSansBold">Draws</h1>

            <div className='shadow-md mb-10'>
                <div className='p-4'>
                    <h1 className="text-2xl mb-2">List of Draws</h1>
                    <button 
                        onClick={() => window.location.href = '/admin/add-draws'}
                        className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-emerald-500 text-white'>
                        Add Draw
                    </button>
                </div>
                <DataTable
                    columns={columns(_btn)}
                    data={data}
                    pagination
                    progressPending={loading}
                    />
            </div>

            <div className='shadow-md mb-10'>
                <h1 className="text-2xl mb-5 p-4">List of Winners</h1>
                <DataTable
                    columns={winnerColumns}
                    data={winners}
                    pagination
                    />
            </div>
        </div>
    )

    function _btn(row) {
        return (
            <div className='flex gap-2'>
                <button 
                    onClick={() => window.location.href = '/admin/make-draws/'+row.key}
                    className='font-ProductSansRegular tracking-wider rounded-md p-2 bg-orange-500 text-white'>
                    Start Draw
                </button>
                <button 
                    onClick={() => window.location.href = '/admin/edit-draws/'+row.key}
                    className='font-ProductSansRegular tracking-wider rounded-md p-2 bg-cyan-500 text-white'>
                    Edit
                </button>
            </div>
        )
    }
}

export default Draws;