import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { loginUser } from "../../firebase/user";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let user = window.localStorage.getItem('@goldUser');
        if(user !== null) {
            window.location.href = "/admin/campaign"
        }
    }, []);

    return (
        <div className="max-w-lg mx-auto h-screen mt-[100px]">
            <div className="shadow-md p-10 flex flex-col h-auto">
                <h1 className="text-2xl font-bold font-ProductSansRegular">Control Panel</h1>
                <span className="mb-5 font-ProductSansRegular">Administrator Access</span>
                <input 
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={username.trim()}
                    onChange={(e) => setUsername(e.target.value)}
                    className="font-ProductSansRegular shadow-md w-full p-2 focus:outline-none mb-2"
                    />
                <input 
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="font-ProductSansRegular shadow-md w-full p-2 focus:outline-none mb-2"
                    />
                    
                <button 
                    disabled={loading}
                    onClick={() => _login()}
                    className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-[#c0a200] w-[100px] text-white'>
                    {loading ? 'Loading' : 'SUBMIT'}
                </button>
            </div>
        </div>
    )

    function _login() {
        if(username === "") {
            toast.error('Please enter username');
            return false;
        }

        if(password === "") {
            toast.error('Please enter password');
            return false;
        }

        let params = {
            username: username,
            password: password,
        }

        setLoading(true);
        loginUser(params).then(res => {
            if(res === 'invalid') {
                setLoading(false);
                toast.error('Invalid credentials');
                return false;
            }else {
                window.localStorage.setItem('@goldUser', res);
                window.location.href = "/admin/campaign"
            }
        })
    }
}

export default Login;