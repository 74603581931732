import { query, onValue, update, remove, set, push, ref, orderByKey, limitToLast, limitToFirst, orderByChild, startAt, equalTo } from "firebase/database";
import { drawsRef, winnersRef, drawRegisterRef, db } from "./";

export function getDraws() {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, drawsRef)), (snapshot) => {
            if(snapshot.val() !== null) {
                let items = []
                snapshot.forEach(child => {
                    items.push({
                        key: child.key,
                        ...child.val()
                    })
                });
                resolve(items);
            }else {
                resolve([]);
            }
        });
    });
}

export function getDrawsByKey(key) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, drawsRef + key);
        onValue(dataRef, (snapshot) => {
            resolve(snapshot.val());
        });
    });
}

export function addDraws(params) {
    return new Promise((resolve, reject) => {
        set(push(ref(db, drawsRef)), params);
        resolve('saved');
    });
}

export function editDraws(key, params) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, drawsRef + key);
        update(dataRef, params);
        resolve('saved');
    });
}

export function deleteDraws(key) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, drawsRef + key);
        remove(dataRef);
        resolve('saved');
    });
}

export function makeDraws(noOfWinners, includePrevWinners, drawId) {
    return new Promise((resolve, reject) => {

        onValue(query(ref(db, drawRegisterRef), orderByChild('drawId'), startAt(drawId), limitToFirst(noOfWinners)), (snapshot) => {
            if(snapshot.val() !== null) {
                let items = []
                snapshot.forEach(child => {
                    if(child.val().winner !== undefined && child.val().winner === "yes" && includePrevWinners === "yes") {
                        items.push({
                            key: child.key,
                            ...child.val()
                        })
                        return;
                    }

                    items.push({
                        key: child.key,
                        ...child.val()
                    })
                });
                resolve(items);
            }else {
                resolve([]);
            }
        });
    });
}

export function saveWinners(key, params) {
    return new Promise((resolve, reject) => {
        set(push(ref(db, winnersRef)), {
            ...params,
            drawId_done: key+"_done"
        });
        resolve('saved');
    });
}

export function updateCustomer(key) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, drawRegisterRef + key);
        update(dataRef, {
            winner: 'yes'
        });
        resolve('saved');
    });
}

export function getWinners(key) {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, winnersRef), orderByChild('drawId_done'), equalTo(key+'_done')), (snapshot) => {
            if(snapshot.val() !== null) {
                let items = []
                snapshot.forEach(child => {
                    items.push({
                        key: child.key,
                        ...child.val()
                    })
                });
                resolve(items);
            }else {
                resolve([]);
            }
        });
    });
}