import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Home from './views';
import Layout from './layout/layout';
import Login from './views/admin/login';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/:code' component={Home} />
          <Route path='/auth/cpanel' component={Login} />
          <Route path='/admin' component={Layout} />
        </Switch>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
