import { orderByChild, set, push, equalTo, query, onValue, ref } from "firebase/database";
import { couponsRef, db } from "./";

export function addCoupons(params) {
    return new Promise((resolve, reject) => {
        set(push(ref(db, couponsRef)), params);
        resolve('saved');
    });
}

export function checkCoupon(code) {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, couponsRef), orderByChild('code'), equalTo(code)), (snapshot) => {
            if(snapshot.val() === null) {
                resolve('not_exist');
            }else {
                var items = []
                snapshot.forEach(child => {
                    items.push({
                        key: child.key,
                        ...child.val()
                    });
                })
                resolve(items[0]);
            }
        });
    });
}

export function getCouponsByCampaign(campaignId) {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, couponsRef), orderByChild('campaignId'), equalTo(campaignId)), (snapshot) => {
            if(snapshot.val() === null) {
                resolve([]);
            }else {
                var items = []
                snapshot.forEach(child => {
                    items.push({
                        key: child.key,
                        ...child.val()
                    });
                })
                resolve(items);
            }
        });
    });
}