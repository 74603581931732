import moment from "moment";
import { useState } from "react";
import toast from 'react-hot-toast';
import { addCampaign } from "../../../firebase/campaign";

const Add = (props) => {

    const [name, setName]           = useState('');
    const [startdate, setStartdate] = useState('');
    const [enddate, setEnddate]     = useState('');
    const [status, setStatus]       = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <h1 className="text-4xl mb-10 font-ProductSansBold">Add Campaign</h1>

            <button 
                disabled={loading}
                onClick={() => window.location.href = '/admin/campaign'}
                className='font-ProductSansRegular tracking-wider mt-4 bg-slate-600 text-white rounded-md p-2'>
                Back
            </button>

            <div className='shadow-md mb-10 mt-10'>
                <div className='p-4'>

                    <span className="font-ProductSansBold">Campaign Name</span>
                    <input 
                        type="text"
                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                        placeholder='Ex: My Campaign'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                        
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <div>
                            <span className="font-ProductSansBold">Start Date</span>
                            <input 
                                type="date"
                                className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                                placeholder='Start Date'
                                value={startdate}
                                onChange={(e) => setStartdate(e.target.value)}
                                />
                        </div>
                        
                        <div>
                            <span className="font-ProductSansBold">End Date</span>
                            <input 
                                type="date"
                                className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                                placeholder='End Date'
                                value={enddate}
                                onChange={(e) => setEnddate(e.target.value)}
                                />
                        </div>
                    </div>

                    <span className="font-ProductSansBold">Status</span>
                    <select
                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        >
                        <option value="">Please Select</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>

                    <button 
                        disabled={loading}
                        onClick={() => _save()}
                        className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-emerald-500 text-white'>
                        {loading ? 'Loading' : 'Submit'}
                    </button>
                </div>
            </div>
            
        </div>
    )

    function _save() {
        if(name === "") {
            toast.error('Please enter campaign name');
            return false;
        }

        if(startdate === "") {
            toast.error('Please enter start date');
            return false;
        }

        if(enddate === "") {
            toast.error('Please enter end date');
            return false;
        }

        if(status === "") {
            toast.error('Please select status');
            return false;
        }

        let id = Math.floor(1000000 + Math.random() * 9000000); 
        let params = {
            name: name,
            startdate: moment(startdate).format('YYYY-MM-DD'),
            enddate: moment(enddate).format('YYYY-MM-DD'),
            status: status,
            id: id.toString()
        }

        setLoading(true);
        addCampaign(params).then(res => {
            if(res === "saved") {
                setTimeout(() => {
                    window.location.href = '/admin/campaign';
                }, 1500)
            }else {
                setLoading(false);
                toast.error('Error occur');
                return false;
            }
        })
    }
}

export default Add;