import { useEffect, useState } from 'react';
import { changePassword, checkOldPassword } from '../../firebase/user';
import toast from 'react-hot-toast';

const ChangePassword = (props) => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <h1 className="text-4xl mb-10 font-ProductSansBold">Change Password</h1>

            <div className='mb-10'>
                <div className='p-4 grid grid-cols-1'>
                    <input 
                        type="password"
                        name="password"
                        placeholder="Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="font-ProductSansRegular shadow-md w-full p-2 focus:outline-none mb-2"
                        />
                    <input 
                        type="password"
                        name="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="font-ProductSansRegular shadow-md w-full p-2 focus:outline-none mb-2"
                        />
                    <input 
                        type="password"
                        name="password"
                        placeholder="Repeat New Password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        className="font-ProductSansRegular shadow-md w-full p-2 focus:outline-none mb-2"
                        />
                    <button 
                        disabled={loading}
                        onClick={() => _save()}
                        className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-emerald-500 text-white'>
                        {loading ? 'Loading' : 'Submit'}
                    </button>
                </div>
            </div>
            
        </div>
    )

    function _save() {

        if(oldPassword === "") {
            toast.error('Please enter old password');
            return false;
        }

        if(newPassword === "") {
            toast.error('Please enter new password');
            return false;
        }

        if(repeatPassword === "") {
            toast.error('Please enter repeat password');
            return false;
        }

        if(repeatPassword !== newPassword) {
            toast.error('Repeat password not match to new password');
            return false;
        }

        let user = window.localStorage.getItem('@goldUser');
        setLoading(true);
        checkOldPassword(user, oldPassword).then(res => {
            if(res === "not_equal") {
                setLoading(false);
                toast.error('Old password not match in the system');
                return false;
            }else {
                changePassword(user, newPassword).then(res => {
                    toast.success('New password successfully changed');
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                })
            }
        })
    }
}

export default ChangePassword;