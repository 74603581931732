import moment from "moment";
import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { getDrawsByKey, editDraws, deleteDraws } from "../../../firebase/draws";

const Edit = (props) => {

    const { key } = props.match.params;

    const [title, setTitle]           = useState('');
    const [noOfWinners, setNoOfWinners] = useState('');
    const [includePrevWinners, setIncludePrevWinners]     = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDrawsByKey(key).then(res => {
            setTitle(res.title);
            setNoOfWinners(res.noOfWinners);
            setIncludePrevWinners(res.includePrevWinners);
        });
    }, [])

    return (
        <div>
            <h1 className="text-4xl mb-10 font-ProductSansBold">Edit Draw</h1>

            <button 
                disabled={loading}
                onClick={() => window.location.href = '/admin/draws'}
                className='font-ProductSansRegular tracking-wider mt-4 bg-slate-600 text-white rounded-md p-2'>
                Back
            </button>

            <div className='shadow-md mb-10 mt-10'>
                <div className='p-4'>

                    <span className="font-ProductSansBold">Title</span>
                    <input 
                        type="text"
                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                        placeholder='Ex: My Draw'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        />
                    
                    <span className="font-ProductSansBold">Number of Winners</span>
                    <input 
                        type="number"
                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                        placeholder='Ex: 10'
                        value={noOfWinners}
                        onChange={(e) => setNoOfWinners(e.target.value)}
                        />

                    <span className="font-ProductSansBold">Include Previous Winners</span>
                    <select
                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-2 mb-4 focus:outline-none border-2'
                        value={includePrevWinners}
                        onChange={(e) => setIncludePrevWinners(e.target.value)}
                        >
                        <option value="">Please Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>

                    <div className="flex gap-2">
                        <button 
                            disabled={loading}
                            onClick={() => _save()}
                            className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-emerald-500 text-white'>
                            {loading ? 'Loading' : 'Submit'}
                        </button>

                        <button 
                            disabled={loading}
                            onClick={() => _delete()}
                            className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-red-500 text-white'>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    )

    function _save() {
        if(title === "") {
            toast.error('Please enter draw title');
            return false;
        }

        if(noOfWinners === "") {
            toast.error('Please enter number of winners');
            return false;
        }

        if(includePrevWinners === "") {
            toast.error('Please select if previosu winner are included');
            return false;
        }

        let params = {
            title: title,
            noOfWinners: noOfWinners,
            includePrevWinners: includePrevWinners
        }

        setLoading(true);
        editDraws(key, params).then(res => {
            if(res === "saved") {
                setTimeout(() => {
                    window.location.href = '/admin/draws';
                }, 1500)
            }else {
                setLoading(false);
                toast.error('Error occur');
                return false;
            }
        })
    }

    function _delete() {
        if(window.confirm('Are you sure you want to delete this draw?')) {
            deleteDraws(key).then(res => {
                if(res === "saved") {
                    window.location.href = '/admin/draws';
                }else {
                    setLoading(false);
                    toast.error('Error occur');
                    return false;
                }
            })
        }
    }
}

export default Edit;