import { Route, Switch } from "react-router-dom";

import { MdCampaign, MdOutlineAppRegistration, MdOutlineLogin, MdPowerSettingsNew, MdOutlineKeyboardHide } from 'react-icons/md';

import Campaign from '../views/admin/campaign';
import AddCampaign from '../views/admin/campaign/add';
import EditCampaign from '../views/admin/campaign/edit';
import GenerateCampaignCoupon from '../views/admin/campaign/generate';

import Draws from '../views/admin/draws';
import AddDraws from '../views/admin/draws/add';
import EditDraws from '../views/admin/draws/edit';
import MakeDraw from '../views/admin/draws/makeDraw';

import Entries from '../views/admin/entries';
import { useEffect } from "react";
import ChangePassword from "../views/admin/changePassword";

const Layout = (props) => {

    useEffect(() => {
        let user = window.localStorage.getItem('@goldUser');
        if(user === null) {
            window.location.href = "/auth/cpanel"
        }
    }, []);

    return (
        <div className="flex">
            <section className="h-screen shadow-md p-4 w-72 bg-slate-800">
                <h1 className="text-3xl font-bold text-white">Kyma Gold</h1>
                <div className="mb-10 text-white">Control Panel</div>

                <ListItems 
                    title="Campaign"
                    link="/admin/campaign"
                    icon={<MdCampaign size={25} color="#fff"/>}
                    />
                <ListItems 
                    title="Draws"
                    link="/admin/draws"
                    icon={<MdOutlineAppRegistration size={25} color="#fff"/>}
                    />
                <ListItems 
                    title="Entries"
                    link="/admin/entries"
                    icon={<MdOutlineLogin size={25} color="#fff"/>}
                    />
                <ListItems 
                    title="Change Password"
                    link="/admin/password"
                    icon={<MdOutlineKeyboardHide size={25} color="#fff"/>}
                    />

                <ListLogout 
                    title="Logout"
                    onClick={() => {
                        window.localStorage.removeItem('@goldUser');
                        window.location.href = "/auth/cpanel"
                    }}
                    icon={<MdPowerSettingsNew size={25} color="#fff"/>}
                    />
            </section>
            <main className="h-screen p-4 w-full">
                <Switch>

                    <Route path='/admin/campaign' component={Campaign} />
                    <Route path='/admin/add-campaign' component={AddCampaign} />
                    <Route path='/admin/edit-campaign/:key' component={EditCampaign} />
                    <Route path='/admin/generate-campaign-coupon/:key' component={GenerateCampaignCoupon} />

                    <Route path='/admin/draws' component={Draws} />
                    <Route path='/admin/add-draws' component={AddDraws} />
                    <Route path='/admin/edit-draws/:key' component={EditDraws} />
                    <Route path='/admin/make-draws/:key' component={MakeDraw} />

                    <Route path='/admin/entries' component={Entries} />

                    <Route path='/admin/password' component={ChangePassword} />
                </Switch>
            </main>
        </div>
    )
}

const ListItems = ({
    title,
    link,
    icon
}) => {
    return (
        <div className="pt-2 pb-2 flex">
            <div className="mr-5">
                {icon}
            </div>
            <div>
                <a href={link} className="text-white">
                    {title}
                </a>
            </div>
        </div>
    )
}

const ListLogout = ({
    title,
    onClick,
    icon
}) => {
    return (
        <div className="mt-20 mb-2 flex">
            <div className="mr-5">
                {icon}
            </div>
            <div>
                <a href="#" onClick={onClick} className="text-white">
                    {title}
                </a>
            </div>
        </div>
    )
}

export default Layout;