import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyB4pVeK-XEi6_wW-yRJTOyyh7Anwjl545Y",
    authDomain: "kyma-gold.firebaseapp.com",
    databaseURL: "https://kyma-gold-default-rtdb.firebaseio.com",
    projectId: "kyma-gold",
    storageBucket: "kyma-gold.appspot.com",
    messagingSenderId: "694358038443",
    appId: "1:694358038443:web:a619fe67d150a30050a4ee"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

export const couponsRef         = 'coupons/';
export const drawRegisterRef    = 'draw_registration/';
export const campaignRef        = 'campaign';
export const drawsRef           = 'draws/';
export const winnersRef         = 'winners/';
export const usersRef           = 'users/';

//
// Enable this is your running in local
//
// export const couponsRef         = 'dev_coupons/';
// export const drawRegisterRef    = 'dev_draw_registration/';
// export const campaignRef        = 'dev_campaign/';
// export const drawsRef           = 'dev_draws/';
// export const winnersRef         = 'dev_winners/';


