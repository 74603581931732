import Header from '../components/header';
import Footer from '../components/footer';

import banner from '../assets/images/kyma-banner.png';
import formRight from '../assets/images/form-bg-right.png';
import formLeft from '../assets/images/form-bg-left.png';
import logo from '../assets/images/logo-main.png';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { checkRegistration, registerUserDraw } from '../firebase/register';
import { addCoupons, checkCoupon } from '../firebase/coupons';
import { checkCampaign } from '../firebase/campaign';
import moment from 'moment';

//var data = require('../assets/qr.json');

export default function Code(props) {

    let code = props.match.params.code;

    // const router = useRouter()
    // const { code } = router.query

    const [loading, setLoading] = useState(false);
    const [fullname, setFullname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [hideBtn, setHideBtn] = useState(false);
  
    useEffect(() => {
        // for(var i=0; i<data.data.length; i++) {
        //     let params = {
        //         code: data.data[i].Coupon,
        //         campaignId: '2df6e31b-ac60-4e1e-a567-74aa045b18e9',
        //         generatedDate: moment().format('YYYY-MM-DD')
        //     }
        //     addCoupons(params);
        // }

        // if(code) {
        //     checkRegistration(code).then(res => {
        //         if(res === "in_used") {
        //             toast.error('This coupon already used');
        //             setHideBtn(true);
        //             return false;
        //         }else {
        //             setHideBtn(false);
        //             return false;
        //         }
        //     });
        // }
    }, []);

    return (
        <div>
            <Header 
                title="Kyma Gold"
                content=""
                />
                
            <main>
                
                {/* Banner */}
                <div className='w-full'>
                    <img 
                        src={banner}
                        className="w-full h-[400px] md:h-[700px]"
                        style={{objectFit: 'cover'}}
                        alt=""
                        />
                    <div className='absolute top-1/4 md:top-1/2 w-full text-center'>
                        <h1 className='text-white text-4xl md:text-6xl font-ProductSansBold'>KYMA GOLD</h1>
                    </div>
                </div>

                {/* Form */}
                <div className='p-4 md:container mx-auto max-w-6xl relative mt-8'>
                    <div className='relative md:h-[95vw]'>
                        
                        {/* Form Right */}
                        <div className='grid md:grid-cols-3 w-full z-10'>
                        <div className=""></div>
                        <div className="col-span-2 relative">
                            <img 
                                src={formRight}
                                className="w-full h-[1000px] md:h-full rounded-xl"
                                style={{objectFit: 'cover'}}
                                alt=""
                                />
                            <div className='absolute top-0 p-10 w-full'>
                            <img 
                                src={logo}
                                width={150}
                                alt=""
                                />
                            <h1 className='text-4xl text-[#c0a200] font-ProductSansBold mt-8'>REGISTRATION FORM</h1>
                            <span className='text-white font-ProductSansRegular mt-8'>(PLEASE FILL YOUR DETAILS)</span>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-8'>
                                <div>
                                    <input 
                                        type="text"
                                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-4 focus:outline-none'
                                        placeholder='FULL NAME'
                                        value={fullname}
                                        onChange={(e) => setFullname(e.target.value)}
                                        />
                                    </div>
                                <div>
                                    <input 
                                        type="number"
                                        className='w-full p-3 rounded-md font-ProductSansRegular tracking-wider mt-4 focus:outline-none'
                                        placeholder='PHONE NUMBER'
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                </div>
                            </div>

                            <input 
                                type="email"
                                className='w-full p-3 rounded-md mt-8 font-ProductSansRegular tracking-wider focus:outline-none'
                                placeholder='EMAIL'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                />

                            <textarea 
                                placeholder='ADDRESS'
                                className='w-full p-3 rounded-md mt-8 font-ProductSansRegular tracking-wider focus:outline-none'
                                rows={5}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                />
                            {hideBtn ? null : 
                                <button 
                                onClick={() => _save()}
                                disabled={loading}
                                className='font-ProductSansRegular tracking-wider mt-8 rounded-md p-3 bg-[#c0a200] w-[100px] text-white'>
                                SUBMIT
                                </button>
                            }
                            
                            <p className='font-ProductSansRegular tracking-wider mt-12'>
                                KYMA GOLD Jeerakashala/Karma rice is a manufactured by BURDHAMAN IMAX AGRO products. For the high qualityof rice we used to procure paddy from burdwan paddy field before two years and milling only at the time of shipment. So our rice will get very fantastic cooking. After cooking this will give you superb taste as well great natural aroma.
                            </p>
                            </div>
                        </div>
                        </div>

                        {/* Form Left */}
                        <div className='grid grid-cols-1 md:grid-cols-3 md:absolute md:top-1/3 w-full' style={{zIndex: -100}}>
                        <div className="col-span-2 relative">
                            <img 
                                src={formLeft}
                                className="w-full"
                                style={{objectFit: 'cover'}}
                                alt=""
                            />
                        </div>
                        <div className=""></div>
                        </div>

                    </div>
                </div>

                {/* About */}
                <div className='md:container mt-8 mx-auto max-w-6xl p-4'>
                    <h1 className='mb-4 font-ProductSansBold text-4xl text-[#464646]'>ABOUT US</h1>
                    <p className='mb-4 font-ProductSansRegular text-lg text-[#464646]'>VARISH TRADERS is a professionally managed organization that specializes in providing comprehensive range of Basmati & Non Basmati Rice LIKE JEERAKASHALA RICE/KAYMA RICE,SWARNA RAW RICE,SWARNA BOILED RICE, IR64 RAW RICE ,IR64 BOILED RICE,PARAMAL RICE,LACHKARI KOLUM RICE,SONA MASOORI RICE AND DEOILED RICE BRAN. Started in 1981, the company is located KERALA, India. the company has emerged as key player in the domain of Agricultural Industry. Below are some highlights of the company that help us to distinguish us in International market.</p>
                    <button onClick={() => window.open('https://varishtraders.com', '_blank')} className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-3 bg-[#c0a200] text-white mb-10'>
                        <span className='ml-4 mr-4'>VISIT OUR WEBSITE</span>
                    </button>
                </div>

            </main>

            <Footer />
        </div>
    )

    function _save() {
        if(fullname === "") {
            toast.error('Please enter your full name');
            return false;
        }

        if(phoneNumber === "") {
            toast.error('Please enter your phone number');
            return false;
        }

        if(email === "") {
            toast.error('Please enter your email address');
            return false;
        }
        var emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(emailValid === null) {
            toast.error('Please enter valid email address');
            return false;
        }

        if(address === "") {
            toast.error('Please enter your address');
            return false;
        }

        let id = Math.floor(1000000 + Math.random() * 9000000);

        let params = {
            code: code,
            fullname: fullname,
            phoneNumber: phoneNumber,
            email: email,
            address: address,
            drawId: id.toString(),
            registeredDate: moment().format('YYYY-MM-DD h:mm:ss')
        }
        
        setLoading(true);
        checkCoupon(code).then(res => {
            if(res === 'not_exist') {
                toast.error('This coupon is not exist to the system');
                setLoading(false);
                return false;
            }else {

                checkCampaign(res.campaignId).then(data => {
                    if(data === "inactive") {
                        toast.error('This campaign is no longer available');
                        setLoading(false);
                        return false;
                    }else {
                        checkRegistration(code).then(res => {
                            if(res === "in_used") {
                                toast.error('This coupon already used');
                                setLoading(false);
                                return false;
                            }else {
                                registerUserDraw(params).then(res => {
                                    if(res === "saved") {
                                        toast.success('Registration successfully. Thank you for Choosing Kyma Gold.');
                        
                                        setLoading(false);
                                        setFullname('');
                                        setPhoneNumber('');
                                        setEmail('');
                                        setAddress('');
                        
                                    }else {
                                        toast.error('An error occur. Please try again');
                                        return false;
                                    }
                                });
                            }
                        });
                    }
                })
            }
        });
    }
}
