import logo from '../assets/images/logo-main.png'

const Header = (props) => {
    return (
        <div className="bg-black/80 fixed w-full z-40">
            {/* <Head>
                <title>{props.title}</title>
                <meta name="description" content={props.content} />
                <link rel="icon" href="/favicon.png" />
            </Head> */}

            <nav className="container mx-auto flex p-4 sticky">
                <div>
                    <img 
                        src={logo}
                        width={180}
                        alt=""
                        />
                </div>
            </nav>
        </div>
    )
}

export default Header;