import { orderByChild, set, push, equalTo, query, onValue, ref } from "firebase/database";
import { drawRegisterRef, db } from "./";

export function checkRegistration(code) {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, drawRegisterRef), orderByChild('code'), equalTo(code)), (snapshot) => {
            if(snapshot.val() === null) {
                resolve('not_used');
            }else {
                resolve('in_used');
            }
        });
    });
}

export function registerUserDraw(params) {
    return new Promise((resolve, reject) => {
        set(push(ref(db, drawRegisterRef)), params);
        resolve('saved');
    });
}

export function getEntries() {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, drawRegisterRef)), (snapshot) => {
            if(snapshot.val() !== null) {
                let items = []
                snapshot.forEach(child => {
                    items.push({
                        key: child.key,
                        ...child.val()
                    })
                });
                resolve(items);
            }else {
                resolve([]);
            }
        });
    });
}