import { orderByChild, equalTo, query, onValue, update, remove, set, push, ref } from "firebase/database";
import { campaignRef, db } from "./";

export function checkCampaign(id) {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, campaignRef), orderByChild('id'), equalTo(id)), (snapshot) => {
            if(snapshot.val() !== null) {
                snapshot.forEach(child => {
                    if(child.val().status === "active") {
                        resolve('active');
                    }else {
                        resolve('inactive');
                    }
                })
            }else {
                resolve('inactive');
            }
        });
    });
}

export function getCampaign() {
    return new Promise((resolve, reject) => {
        onValue(query(ref(db, campaignRef)), (snapshot) => {
            if(snapshot.val() !== null) {
                let items = []
                snapshot.forEach(child => {
                    items.push({
                        key: child.key,
                        ...child.val()
                    })
                });
                resolve(items);
            }else {
                resolve([]);
            }
        });
    });
}

export function getCampaignByKey(key) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, campaignRef + key);
        onValue(dataRef, (snapshot) => {
            resolve(snapshot.val());
        });
    });
}

export function addCampaign(params) {
    return new Promise((resolve, reject) => {
        set(push(ref(db, campaignRef)), params);
        resolve('saved');
    });
}

export function editCampaign(key, params) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, campaignRef + key);
        update(dataRef, params);
        resolve('saved');
    });
}

export function deleteCampaign(key) {
    return new Promise((resolve, reject) => {
        const dataRef = ref(db, campaignRef + key);
        remove(dataRef);
        resolve('saved');
    });
}