import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getEntries } from '../../../firebase/register';

const columns = [
    {
        name: 'Full Name',
        selector: row => row.fullname,
    },
    {
        name: 'Phone Number',
        selector: row => row.phoneNumber,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Registered Date',
        selector: row => row.registeredDate,
    },
];

const Entries = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getEntries().then(res => {
            setData(res);
            setLoading(false);
        });
    },[]);

    return (
        <div>
            <h1 className="text-4xl mb-10 font-ProductSansBold">Entries</h1>

            <div className='shadow-md mb-10'>
                <h1 className="text-2xl mb-5 p-4">List of Entries</h1>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    progressPending={loading}
                    />
            </div>
        </div>
    )
}

export default Entries;