import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import {makeDraws, getWinners, getDrawsByKey, saveWinners, updateCustomer } from '../../../firebase/draws';
import moment from 'moment';
import { registerUserDraw } from '../../../firebase/register';

const columns = () => [
    {
        name: 'Full Name',
        selector: row => row.fullname,
    },
    {
        name: 'Phone Number',
        selector: row => row.phoneNumber,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Draw Date',
        selector: row => row.drawDate,
    }
];

const MakeDraw = (props) => {

    const { key } = props.match.params;

    const [data, setData] = useState([]);
    const [noOfWinners, setNoOfWinners] = useState(0);
    const [includePrevWinners, setIncludePrevWinners] = useState(0);
    const [loading, setLoading] = useState(true);
    const [generateLoading, setGenerateLoading] = useState(false);

    useEffect(() => {
        getDrawsByKey(key).then(res => {
            setNoOfWinners(parseInt(res.noOfWinners))
            setIncludePrevWinners(res.includePrevWinners);
        });

        getWinners(key).then(res => {
            setData(res);
            setLoading(false);
        })
    },[]);

    return (
        <div className="main">
            <h1 className="text-4xl mb-10 font-ProductSansBold">Make A Draw</h1>

            <button 
                disabled={loading}
                onClick={() => window.location.href = '/admin/campaign'}
                className='font-ProductSansRegular tracking-wider mt-4 bg-slate-600 text-white rounded-md p-2'>
                Back
            </button>

            <div className='shadow-md mb-10 mt-10'>
                <div className='p-4'>
                    <div className='mb-4'>
                        <span className="font-ProductSansBold">Draw a Winners</span>
                    </div>
                    <button 
                        disabled={generateLoading}
                        onClick={() => _startDraw()}
                        className='font-ProductSansRegular tracking-wider h-50 rounded-md p-2 bg-emerald-500 text-white'>
                        {generateLoading ? 'Please wait... Draw on progress' : 'Start Draw'}
                    </button>
                </div>
            </div>

            <div className='shadow-md mb-10 mt-10'>
                <div className='p-4'>
                    <h1 className="text-2xl mb-2">List of Winners</h1>
                </div>
                
                <DataTable
                    columns={columns()}
                    data={data}
                    noHeader
                    progressPending={loading}
                    highlightOnHover
                    />
            </div>
            
        </div>
    )

    function _startDraw() {

        setGenerateLoading(true);
        let drawId = Math.floor(1000000 + Math.random() * 9000000);
        makeDraws(noOfWinners, includePrevWinners, drawId.toString()).then(res => {

            if(res.length !==0) {
                for(let i=0; i<res.length; i++) {
                    let params = {
                        ...res[i],
                        drawDate: moment().format('YYYY-MM-DD')
                    }
                    saveWinners(key, params);
                    updateCustomer(res[i].key);
                }

                setTimeout(() => {
                    window.location.reload();
                }, 10000);
            }
        })

        // let total = 0;
        // for(var i=0; i<50; i++) {
        //     total += 1
        //     let id = Math.floor(1000000 + Math.random() * 9000000);
        //     let params = {
        //         code: '123'+total,
        //         fullname: 'mike'+total,
        //         phoneNumber: '01230123'+total,
        //         email: 'email@com.com'+total,
        //         address: 'test-'+total,
        //         drawId: id.toString(),
        //         registeredDate: moment().format('YYYY-MM-DD h:mm:ss')
        //     }

        //     registerUserDraw(params);
        // }
    }
}

export default MakeDraw;