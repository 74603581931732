import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const ExportFile = ({
    fileData,
    fileName
}) => {

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheet.sheet;charset-UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = async () => {

        let newArr = [];
        if(fileData.length !== 0) {
            for(let i=0; i<fileData.length; i++) {
                newArr.push({
                    code: fileData[i].code, 
                    link: 'https://draw.varishtraders.com/'+fileData[i].code.trim()
                });
            }
        }

        const ws = XLSX.utils.json_to_sheet(newArr);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension);
    } 

    return (
        <button 
            onClick={exportToExcel}
            className='font-ProductSansRegular tracking-wider mt-4 rounded-md p-2 bg-emerald-500 text-white'>
            Export
        </button>
    );

}

export default ExportFile;